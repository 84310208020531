import axios from 'axios';
import server from 'util/Server';
import Swal from "sweetalert2"
import { sessionCaducada } from 'util/funciones'
import { CATEGORIA } from 'constants/entidades';

export const getMovimientos = async (data, n, tipo) => {
  return new Promise(function (resolve, reject) {
    let t = typeof tipo === "undefined" ? "" : tipo
    let s = '/';
    if (tipo === '-dominio') {
      s = '';
    }
    axios.post(server.host + server.puertoCentury + "/api/movimiento/movimientostipo" + s + t, data, { headers: { Authorization: server.obtenerToken() } })
      .then((response) => {
        let movimientos = []
        let datos = response.data.mensaje
        if (datos != null) {
          if (datos.length === 0 && n === 2) {
            Swal.fire({ icon: 'warning', title: 'No se encontraron datos con las fechas seleccionadas', text: 'Intente con otras fechas', })
            resolve([])
          }
          datos.forEach((movimiento) => {
            movimientos.push({
              movimientos: movimiento
            })
          })
          resolve(movimientos)
        } else if (response.status === server.responseSesionCaducada) {
          datos = [];
          resolve(datos);
          sessionCaducada()
        } else {
          resolve([])
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const getHistorialRecargas = async (datos) => {
  return new Promise(function (resolve, reject) {
    axios.post(server.host + server.puertoCentury + "/api/movimiento/historico-recargas", datos, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

// Historial de pagos por clientes de recaudos
export const getHistorialPagos = async (id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(server.host + server.puertoCentury + "/api/cupos/getcliente-empresas/recaudos/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

// Envio de pagos por clientes de recaudos
export const setPagos = async (datos) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(server.host + server.puertoCentury + "/api/cupos/getcliente-empresas/recaudos/", datos, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};




export const findConceptoListaEmpresasByID = async (identificadorEmpresa) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + "/api/concepto/get-concepto/" + identificadorEmpresa, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};
export const updateConcepto = async (identificadorConcepto, bodyConcepto) => {
  return new Promise(function (resolve, reject) {
    axios.put(`${server.host}${server.puertoCentury}/api/concepto/update-concepto/${identificadorConcepto}`, bodyConcepto, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(true)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};


export const crearNuevoConceptoPost = async (bodyConcepto) => {
  return new Promise(function (resolve, reject) {
    axios.post(server.host + server.puertoCentury + "/api/concepto/crear-concepto/", bodyConcepto, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          resolve(true)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const getFE = async (bodyConcepto) => {
  return new Promise((resolve, reject) => {
    axios.post(`${server.host}${server.puertoMSfe}/guru.facturacion/fe-monitor`, bodyConcepto,
      { mode: 'cors', headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      })
  })
};
export const getNC = async (bodyConcepto) => {
  return new Promise((resolve, reject) => {
    axios.post(`${server.host}${server.puertoMSfe}/guru.facturacion/notas_credito`, bodyConcepto,
      { mode: 'cors', headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      })
  })
};
export const updateFacturacionElectronica = async (bodyConcepto) => {
  return new Promise((resolve, reject) => {
    axios.put(`http://ws.desarrollo.eds.terpel.sclbox.com:${server.puertoLazoLite}/guru.facturacion/fe-no-autorizadas`, bodyConcepto,
      { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data;
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      })
  })
};


export const findConceptoInventarioByEmpresasID = async (identificadorEmpresa) => {
  return new Promise(function (resolve, reject) {
    axios.post(server.host + server.puertoCentury + "/api/concepto/get-concepto-empresa/", identificadorEmpresa, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data.data
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};





export const getDetalles = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + "/api/movimiento/detalles/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.mensaje[0];
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

export const findMovementInfoOrderDetails = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + "/api/movimiento/get-info-movimientos-detalles/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

export const getSelectEmpresas = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + "/api/empresa/select/empresa/5/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = res.data.data;
          let estaciones = [];
          if (datos != null) {
            datos.forEach((empresa) => {
              estaciones.push(empresa);
            });
          }
          resolve(estaciones);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}

export const findProductsByEmpresaFinalIdAndTipos = async (identificadorEmpresa, tiposProductos, tipoNegocio = 'S,K') => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/producto/empresa-final/${identificadorEmpresa}/tipo/${tiposProductos}/${tipoNegocio}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta oara obtener los productos segun la empresa
export const findProductsByEmpresasIdAndTipos = async (identificadorEmpresa, tiposProductos, tipoNegocio = 'S,K') => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/producto/empresa/${identificadorEmpresa}/tipo/${tiposProductos}/${tipoNegocio}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para obtener los detalles segun promocion id
export const findDetallesPromociones = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/promocion/get-detalle-promocion/${id}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}

export const setStateProducto = async (idProducto, estado) => {
  return new Promise(function (resolve, reject) {
    axios.put(server.host + server.puertoCentury + `/api/producto/${idProducto}/${estado}`, {}, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          resolve(true);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve(false);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para obtener el posible plu
export const findPosiblePLu = async (identificadorEstacion) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/producto/posible-consecutivo/${identificadorEstacion}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.mensaje[0].consecutivo_actual;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve('');
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para obtener el select de impuesto por empresa
export const findImpuestosSelectByEmpresasId = async (identificadorEstacion) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/impuesto/select/empresa/${identificadorEstacion}/0`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
export const getBusinessServices = async ()=>{
  return axios.get(server.host + server.puertoCentury + `/api/producto/get-business-types`, { headers: { Authorization: server.obtenerToken() } })
}
// rutra para obtener el select de productos
export const findProductoselectByNegociosId = async (identificadorNegocio, tipoProducto) => {
  let sub = ''
  if (tipoProducto && tipoProducto !== '') {
    sub = `/${tipoProducto}`
  }
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/producto/get-ingredientes-todos/${identificadorNegocio}${sub}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para obtener el select de categorias
export const findCategoriasSelectEmpresasByIdV2 = async (identificadorNegocio, identificadorEmpresa = CATEGORIA) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/grupo/select/v2/${identificadorEmpresa}/${identificadorNegocio}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para crear una promocion
export const crearPromocion = async (body) => {
  return new Promise(function (resolve, reject) {
    axios.post(server.host + server.puertoCentury + `/api/promocion/crear-promocion`, body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          resolve(true);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve(false);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para editar las promociones
export const editarPromocion = async (body) => {
  return new Promise(function (resolve, reject) {
    axios.put(`${server.host}${server.puertoCentury}/api/promocion/editar-promocion`, body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          resolve(true);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve(false);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}


export const findProductosEmpresas = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/concepto/get-listado-empresas/${id}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// ruta para importar el excel de ajuste inicial
export const postExcelAjuteCiclico = async (body) => {
  return new Promise(function (resolve, reject) {
    axios.post(`${server.host}${server.puertoCentury}/api/promocion/crear-promocion`, body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          resolve(true);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve(false);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
// enviar formuñairo xd //
export const postEnviarFormulario = async (datos) => {
  return new Promise(function (resolve, reject) {
    axios.post(server.host + server.puertoCentury + "/api/bodega", datos, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          console.log(res);
          resolve(res.data.success);
        } else if (res.status === server.responseSesionCaducada) {
          datos = [];
          resolve(true);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

// editar formuñairo xd //
export const editarFormularioBodegas = async (body) => {
  return new Promise(function (resolve, reject) {
    axios.put(`${server.host}${server.puertoCentury}/api/bodega`, body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        console.log(res.status);
        if (res.status === server.responseSucces) {
          resolve(true);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve(false);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}
export const getBodega = async (id) => {
  return new Promise(function (resolve, reject) {
    axios.get(server.host + server.puertoCentury + `/api/bodega/${id}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });

  });
}

export const getProductosPreciosEspeciales = async (identificadorEmpresa, tiposProductos, tipoNegocio = 'S,K') => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        server.host +
        server.puertoMovimiento +
        `/api/reportes/precios-especiales/empresa/${identificadorEmpresa}/tipo-producto/${tiposProductos}/tipo-negocio/${tipoNegocio}`,
        { headers: { Authorization: server.obtenerToken() } }
      )
      .then(res => {
        if (res.status === server.responseOK) {
          const datos = res.data
          resolve(datos)
        } else if (
          res.status === server.responseSesionCaducada ||
          res.status === server.responseErrorServidor
        ) {
          resolve([])
          sessionCaducada()
        }
      })
      .catch(err => {
        console.error(err)
        reject(err, undefined)
      })
  })
}

export const activarInactivarPrecioEspecial = async (idProducto, status) => {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        server.host + server.puertoCentury + `/api/producto/activar-inactivar-precio-especial/${idProducto}/estado/${status}`,
        {},
        { headers: { Authorization: server.obtenerToken() } }
      )
      .then(res => {
        if (res.status === server.responseSucces) {
          resolve(res)
        } else if (
          res.status === server.responseSesionCaducada ||
          res.status === server.responseErrorServidor
        ) {
          resolve(false)
          sessionCaducada()
        }
      })
      .catch(err => {
        reject(err, undefined)
      })
  })
}

export const eliminarPrecioEspecial = async idProducto => {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        server.host + server.puertoCentury + `/api/producto/eliminar/precio-especial/${idProducto}`, {},
        {
          headers: { Authorization: server.obtenerToken() },
          'Content-Type': 'application/json'
        }
      )
      .then(res => {
        if (res.status === server.responseSucces) {
          resolve(res)
        } else if (
          res.status === server.responseSesionCaducada ||
          res.status === server.responseErrorServidor
        ) {
          resolve(false)
          sessionCaducada()
        }
      })
      .catch(err => {
        reject(err, undefined)
      })
  })
}
export const getMotivosAnulacion =async () =>{
  const response = await axios.get(server.host + server.puertoMSfe + `/movimientos/obtener-motivo-anulacion`,
    {
      headers: { Authorization: server.obtenerToken() },
      'Content-Type': 'application/json'
    })
    return response
}