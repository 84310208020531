import React, { useEffect, useState } from 'react'
import './index.css'
import { PieChartOutlined, PlusOutlined } from '@ant-design/icons';
import { Typography } from '@material-ui/core';
import { Popover } from 'antd';
import ListChildrenItemDock from './components/ListChildrenItemDock';
import { useDockMenu } from 'context/DockMenuProvider';
interface IDockItemProps {
  title: string;
  icon: React.ReactNode,
  path?: string
  children?: IDockChildrenItemProps[]
}

export interface IDockChildrenItemProps {
  title: string;
  path: string
}
const DockItemMenu: React.FC<IDockItemProps> = ({ title, children }) => {
  const [open, setOpen] = useState(false);
  const { isVisible } = useDockMenu()

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (!isVisible) {
      hide()
    }
  }, [isVisible])

  return (
    <Popover
      getPopupContainer={() => document.body}
      content={<ListChildrenItemDock items={children || []} hide={hide} />}
      open={open}
      onOpenChange={handleOpenChange}
      trigger="click">
      <div className='dock-item-menu'>
        <PieChartOutlined className='icon-dock-item' />
        <Typography className='title-dock-item'>
          {title}
        </Typography>
        <PlusOutlined />
      </div>
    </Popover>

  )
}

export default DockItemMenu
