import React from 'react'
import './index.css'
import { Button, Image, Typography } from 'antd'
import USER_ICON from '../../assets/images/user_icon.svg'

const ProdileUserCard = () => {
    return (
        <div className='card-user'>
            <section className='user-img-container'>
                <Image
                    className='img-user'
                    preview={false}
                    src={USER_ICON}
                />
            </section>
            <section className='info-user-container'>
                <Typography className='name-user'>Nombre Usuario Largo Completo</Typography>
                <Typography className='detail-user'>Rol de usuario</Typography>
                <Typography className='detail-user'>Identificación: 1007137593</Typography>
                <section className='extra-section'>
                    <Button className='button-view-profile'>
                        Ver Perfil
                    </Button>
                    <Button className='button-logout'>
                        Cerrar sesión
                    </Button>
                </section>

            </section>
        </div>
    )
}

export default ProdileUserCard
