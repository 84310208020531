import React from 'react'
import './index.css'
import DockItemMenu from 'components/DockItemMenu/DockItemMenu'

const DockMenuItemContainer = () => {
    return (
        <div className='container-dock-menu-item'>
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
            <DockItemMenu icon={<></>} title='Configuración' children={[{path:'/',title:'Bodegas'},{path:'/',title:'Surtidores'},{path:'/',title:'Resoluciones'}]} />
        </div>
    )
}

export default DockMenuItemContainer
