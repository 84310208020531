import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define el tipo del contexto
interface VisibilityContextType {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}

// Crea el contexto con un valor inicial vacío
const DockMenuContext = createContext<VisibilityContextType | undefined>(undefined);

// Crea el Provider para el contexto
export const DockMenuProvider = ({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <DockMenuContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </DockMenuContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useDockMenu = () => {
  const context = useContext(DockMenuContext);
  if (!context) {
    throw new Error('useVisibility debe usarse dentro de DockMenuProvider');
  }
  return context;
};
