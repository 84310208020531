import React from 'react'
import AppLayouts from './AppLayouts'
import Routes from '../../app/routes'
import { useSelector } from 'react-redux'
import Storage from 'util/store/Storage'
import Servidor from 'util/Server'
import Axios from 'axios'
import { Toaster } from 'react-hot-toast'
import MenuFlotante from 'components/MenuFlotante'
import PreLoaderTerpel from 'components/PreLoaderTerpel/PreLoaderTerpel'
import { usePermission } from 'Providers/AllowedModuleProvider'
import { App } from 'antd'
import DockMenu from 'components/DockMenu/DockMenu'
const AppLayout = () => {
    const horizontalNavPosition = useSelector(({ settings }) => settings.horizontalNavPosition)
    const permisos = usePermission()
    const navigationStyle = useSelector(({ settings }) => settings.navigationStyle)
    const onGetLayout = layout => {
        switch (layout) {
            case 'inside_the_header':
                return 'InsideHeaderNav'

            case 'above_the_header':
                return 'AboveHeaderNav'

            case 'below_the_header':
                return 'BelowHeaderNav'
            default:
                return 'Vertical'
        }
    }

    const Layout =
        AppLayouts[navigationStyle === 'vertical_navigation' ? 'Vertical' : onGetLayout(horizontalNavPosition)]
    return (
        <Layout>
            <App style={{
                width: '100%',
                height: '100%',
            }}>

                <Routes />
            </App>
            <DockMenu />

        </Layout>
    )
}

class APPClassLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() {
        const { negocio } = Storage.getState()
        if (sessionStorage.getItem('token')) {
            const urlSession = Servidor.host + Servidor.puertoApp + '/api/v1/session'
            Axios.get(urlSession, {
                headers: {
                    Authorization: 'Bearer '.concat(sessionStorage.getItem('token')),
                    identificadorNegocio: negocio
                }
            }).then(response => {
                if (response.status === Servidor.responseOK) {
                    Storage.dispatch({
                        type: 'OBTENCION_CREDENCIALES',
                        usuario: response.data.data,
                        obtencionInfoUsuario: true
                    })
                    this.obtencionDatosUsuarios(response.data.data)
                } else {
                    this.obtencionDatosUsuarios(Storage.getState().usuario)
                }
            })
        }
    }

    obtenerHijosEds = usu => {
        const { infoDominio } = Storage.getState()
        const url1 = '/api/empresa/node/' + usu.empresas_id + '/0'
        const urlMongo = '/api/empresa/select/all/' + infoDominio.negocio_id
        let hijos = ''
        Axios.get(Servidor.host + Servidor.puertoCentury + (usu.tipo === 3 ? urlMongo : url1), {
            headers: { Authorization: 'Bearer '.concat(sessionStorage.getItem('token')) }
        })
            .then(response => {
                if (response.status === 200) {
                    if (usu.tipo === 3) {
                        const empresasId = response.data.data.map((empresa) => empresa.idEds)
                        hijos = empresasId.toString()
                    } else {
                        response.data.data.forEach((item, key) => {
                            if (key === 0) {
                                hijos = item.toString()
                            } else {
                                hijos += ',' + item.toString()
                            }
                        })
                    }
                    Storage.dispatch({
                        type: 'OBTENCION_JERARQUIA_HIJOS',
                        empresasHijos: hijos,
                        usuarioObtenido: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    getModules(usu) {
        let padres = ''

        Axios.get(Servidor.host + Servidor.puertoCentury + '/api/empresa/node/' + usu?.empresas_id + '/1', {
            headers: { Authorization: 'Bearer '.concat(sessionStorage.getItem('token')) }
        })
            .then(response => {
                if (response.status === 200) {
                    response.data.data.forEach((item, key) => {
                        if (key === 0) {
                            padres = item.toString()
                        } else {
                            padres += ',' + item.toString()
                        }
                    })
                    Storage.dispatch({
                        type: 'OBTENCION_JERARQUIA_PADRES',
                        empresasPadres: padres
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    obtencionDatosUsuarios = usu => {
        //Obtencion de nodulos
        let tipoDominio = 'T'
        let tipoNegocio = 'L'
        if (usu.dominio != null || usu.dominio.atributos != null) {
            tipoDominio = usu.dominio.atributos.tipo_unidad
        }

        Storage.dispatch({
            type: 'OBTENCION_AMBIENTES',
            tipoDominio,
            tipoNegocio
        })
        this.getModules(usu)


        //Obtencion de empresas hijas
        let hijos = ''

        switch (usu.tipo) {
            case 1:
            case 4:
            case 3:
            case 5:
                this.obtenerHijosEds(usu);
                break
            case 2:
                Axios.get(
                    Servidor.host + Servidor.puertoCentury + '/api/empresa/obtener-hijo/dominio_id/' + usu.empresas_id,
                    {
                        headers: { Authorization: 'Bearer '.concat(sessionStorage.getItem('token')) }
                    }
                )
                    .then(response => {
                        if (response.status === 200) {
                            const datos = response.data.hijos ?? []
                            datos.forEach((item, key) => {
                                if (key === 0) {
                                    hijos = item.toString()
                                } else {
                                    hijos += ',' + item.toString()
                                }
                            })

                            Storage.dispatch({
                                type: 'OBTENCION_JERARQUIA_HIJOS',
                                empresasHijos: hijos,
                                usuarioObtenido: true
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                break
            default:
                break
        }
    }

    render() {
        let { usuarioObtenido } = Storage.getState()
        return usuarioObtenido ? (
            <>
                <MenuFlotante />
                <AppLayout />


                <Toaster
                    position="top-right"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        // Define default options
                        className: '',
                        style: {
                            background: '#C9961A',
                            color: 'black',
                            width: '650px',
                            height: '60px',
                            padding: '0px',
                            fontSize: '1rem',
                            boxShadow: '0px 0px 10px #A9AAAE'
                        }
                    }}
                />
            </>
        ) : (
            <div style={{ width: '100%' }}>
                <center
                    style={{
                        display: 'flex',
                        height: '100vh'
                    }}
                >
                    <PreLoaderTerpel />
                </center>
            </div>
        )
    }
}

export default APPClassLayout
