import React, { useEffect, useState, useRef } from 'react';
import './index.css';
import ProdileUserCard from 'components/ProfileUserCard/ProdileUserCard';
import DockMenuItemContainer from 'components/DockMenuItemContainer/DockMenuItemContainer';
import { useDockMenu } from 'context/DockMenuProvider';

const DockMenu: React.FC = () => {
    const { isVisible, setIsVisible } = useDockMenu()
    const hideTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const windowHeight = window.innerHeight;
            const bottomThreshold = 100; // Distancia en píxeles desde el borde inferior

            if (e.clientY > windowHeight - bottomThreshold) {
                // Muestra el menú y cancela el temporizador de ocultación si está activo
                if (hideTimeout.current) {
                    clearTimeout(hideTimeout.current);
                    hideTimeout.current = null;
                }
                setIsVisible(true);
            } else if (isVisible) {
                // Si el mouse sale, inicia un temporizador para ocultar el menú después de 3 segundos
                if (!hideTimeout.current) {
                    hideTimeout.current = setTimeout(() => {
                        setIsVisible(false);
                        hideTimeout.current = null;
                    }, 3000); // 3 segundos
                }
            }
        };
        return
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            if (hideTimeout.current) {
                clearTimeout(hideTimeout.current);
            }
        };
    }, [isVisible]); // Dependencia actualizada para manejar cambios en la visibilidad

    return (
        <div className={`dock-menu ${isVisible ? 'visible' : ''}`}>
            {/* Items del menú */}
            <section className='user-card-container'>
                <ProdileUserCard />
            </section>
            <section className='menu-item-container'>
                <DockMenuItemContainer />
            </section>
        </div>
    );
};

export default DockMenu;
