import { setearMiles } from "util/funciones"

export const COLUMNAS_REPORTE_VENTAS_EDS = [{
        field: 'centro_logistico',
        header: 'CENTRO LOGISTICO'
    },
    {
        field: 'centro_beneficio',
        header: 'CENTRO BENEFICIO'
    },
    {
        field: 'codigo_unico',
        header: 'CODIGO'
    },
    {
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'regional',
        header: 'REGIONAL'
    },
    {
        field: 'departamento',
        header: 'DEPARTAMENTO'
    },
    {
        field: 'ciudad',
        header: 'CIUDAD'
    },
    {
        field: 'facturacion',
        header: 'FACTURACION'
    },
    {
        field: 'consecutivo_actual',
        header: 'CON. ACTUAL'
    },
    {
        field: 'consecutivo_anterior',
        header: 'CON. ANTERIOR'
    },
    {
        field: 'turno',
        header: 'TURNO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'codigo_producto',
        header: 'CODIGO P.'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANT.'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'valor_venta',
        header: 'T. VENTA'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'medio_pago',
        header: 'M. DE PAGO'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'islas',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]
export const COLUMNAS_REPORTE_VENTAS_EDS_EXCEL = [{
        field: 'centro_logistico',
        header: 'CENTRO LOGISTICO'
    },
    {
        field: 'centro_beneficio',
        header: 'CENTRO BENEFICIO'
    },
    {
        field: 'codigo_unico',
        header: 'CODIGO'
    },
    {
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'regional',
        header: 'REGIONAL'
    },
    {
        field: 'departamento',
        header: 'DEPARTAMENTO'
    },
    {
        field: 'ciudad',
        header: 'CIUDAD'
    },
    {
        field: 'facturacion',
        header: 'FACTURACION'
    },
    {
        field: 'consecutivo_actual',
        header: 'CON. ACTUAL'
    },
    {
        field: 'consecutivo_anterior',
        header: 'CON. ANTERIOR'
    },
    {
        field: 'turno',
        header: 'TURNO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'codigo_producto',
        header: 'CODIGO P.'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANT.'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'valor_venta',
        header: 'T. VENTA'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'medio_pago',
        header: 'M. DE PAGO'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'islas',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
]

export const COLUMNAS_BALANCE_OPERATIVO = [{
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'existencia_inicial',
        header: 'EXISTENCIA INICIAL'
    },
    {
        field: 'ingreso_factura',
        header: 'INGRESO FACTURA'
    },
    {
        field: 'ingreso_medida',
        header: 'INGRESO MEDIDA'
    },
    {
        field: 'ventas_surtidor',
        header: 'VENTAS SURTIDOR'
    },
    {
        field: 'consumos_propios',
        header: 'CONSUMOS'
    },
    {
        field: 'calibraciones',
        header: 'CALIBRACIONES'
    },
    {
        field: 'otros_movimientos',
        header: 'OTROS MOVIMIENTOS'
    },
    {
        field: 'total_salidas',
        header: 'TOTAL SALIDAS'
    },
    {
        field: 'saldo_teorico',
        header: 'SALDO TEORICO'
    },
    {
        field: 'existencia_final_real',
        header: 'EXISTENCIA FINAL REAL'
    },
    {
        field: 'faltantes_recibo',
        header: 'FALTANTES O SOBRANTES POR RECIBO'
    },
    {
        field: 'faltantes_operativos',
        header: 'FALTANTES O SOBRANTES OPERATIVOS'
    },
    {
        field: 'faltantes_totales',
        header: 'FALTANTES O SOBRANTES TOTALES'
    },
    {
        field: 'faltantes_acumulado',
        header: 'FALTANTES O SOBRANTES ACUMULADO'
    },
    {
        field: 'variacion',
        header: '% VARIACION'
    },
    {
        field: 'agua',
        header: 'AGUA'
    }
]

export const COLUMNAS_DETALLES_VENTAS_DATAGAS = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCIÓN'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'iva',
        header: 'IMPUESTOS'
    },
    {
        field: 'descuento_calculado',
        header: 'DESCUENTO'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_VENTAS_DATAGAS = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'mac',
        header: 'EQUIPO'
    },
    {
        field: 'imp_venta',
        header: 'IMP. VENTA'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    /* {
      field: 'medio_pago',
      header: 'M. DE PAGO'
    },*/
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_CIERRES_DATAGAS = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'jornada',
        header: 'JORNADA'
    },
    {
        field: 'fecha_inicio',
        header: 'FECHA INICIO'
    },
    {
        field: 'fecha_fin',
        header: 'FECHA_FIN'
    },
    {
        field: 'mac',
        header: 'EQUIPO'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_CIERRES_DATAGAS_VENTAS = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'total',
        header: 'TOTAL VENTA'
    }
]

export const COLUMNAS_CIERRES_VENTAS1 = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tipo_factura',
        header: 'TIPO FACTURA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },

    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },

    {
        field: 'impuesto total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]
export const COLUMNAS_CIERRES_VENTAS_WITH_UNIT = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tipo_factura',
        header: 'TIPO FACTURA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },

    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },

    {
        field: 'impuesto',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]
export const COLUMNAS_CIERRES_COMPLETO_VENTAS = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_RECAUDOS_VENTAS = [
  { field: 'cliente', header: 'CLIENTE' },
  { field: 'placa', header: 'PLACA' },
  { field: 'cantidad_recaudo', header: 'CANTIDAD RECAUDO' },
  { field: 'total_recaudo', header: 'TOTAL RECAUDO' },
]

export const COLUMNAS_CIERRES_COMPLETO_ANULACIONES = [{
        field: 'venta',
        header: 'CONSECUTIVO'
    },

    {
        field: 'consecutivo_anulacion',
        header: 'CON. NOTA CREDITO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },

    {
        field: 'impuesto total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total_venta',
        header: 'TOTAL VENTA'
    }
]

export const COLUMNAS_CIERRE_DETALLES_MONGO_ANULACIONES = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    /* {
      field: 'consecutivo_anulacion',
      header: 'CON. NOTA CREDITO'
    }, */
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'impuesto_total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total_venta',
        header: 'TOTAL VENTA'
    }
]

export const COLUMNAS_CIERRES_COMPLETO_CALIBRACIONES = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_CIERRES_DATAGAS_PROMOTOR = [{
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'numero de ventas',
        header: 'NUMERO DE VENTAS'
    },
    {
        field: 'total en ventas',
        header: 'TOTAL EN VENTAS'
    }
]

export const COLUMNAS_CIERRES_DATAGAS_CONSECUTIVO = [{
        field: 'consecutivo_prefijo',
        header: 'PREFIJO'
    },
    {
        field: 'min',
        header: 'MINIMO'
    },
    {
        field: 'max',
        header: 'MAXIMO'
    },
    {
        field: 'cantidad',
        header: 'NUMERO DE VENTAS'
    }
]
export const COLUMNAS_CIERRES_COMPLETO_CONSECUTIVO = [{
        field: 'prefijo',
        header: 'PREFIJO'
    },
    {
        field: 'minimo',
        header: 'MINIMO'
    },
    {
        field: 'maximo',
        header: 'MAXIMO'
    },
    {
        field: 'numero_ventas',
        header: 'NUMERO DE VENTAS'
    }
]
export const COLUMNAS_CIERRE_DIARIO_CONSECUTIVO = [{
        field: 'prefijo',
        header: 'PREFIJO'
    },
    {
        field: 'minimo',
        header: 'MINIMO'
    },
    {
        field: 'maximo',
        header: 'MAXIMO'
    },
    {
        field: 'numero de ventas',
        header: 'NUMERO DE VENTAS'
    }
]
export const COLUMNAS_CIERRES_COMPLETO_TOTALIZADORES = [{
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'grado',
        header: 'GRADO'
    },
    {
        field: 'familiaDescripcion',
        header: 'PRODUCTO'
    },
    {
        field: 'acumuladoVenta',
        header: 'ACUMULADO VENTA INICIAL'
    },
    {
        field: 'acumuladoVentaFinal',
        header: 'ACUMULADO VENTA FINAL'
    },
    {
        field: 'acumuladoVentaDiferencia',
        header: 'ACUMULADO VENTA DIFERENCIA'
    },
    {
        field: 'acumuladoVolumen',
        header: 'ACUMULADO VOLUMEN INICIAL'
    },
    {
        field: 'acumuladoVolumenFinal',
        header: 'ACUMULADO VOLUMEN FINAL'
    },
    {
        field: 'acumuladoVolumenDiferencia',
        header: 'ACUMULADO VOLUMEN DIFERENCIA'
    }
]

export const COLUMNAS_CIERRES_DESPACHO_PRODUCTOS = [{
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'total',
        header: 'VENTA TOTAL'
    }
]
export const COLUMNAS_CIERRE_DIARIO_DESPACHO_PRODUCTOS = [{
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    }
]

export const COLUMNAS_CIERRES_DATAGAS_TOTALIZADORES = [{
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'total',
        header: 'SUBTOTAL'
    }
]

export const COLUMNAS_CIERRES_DATAGAS_PAGOS = [{
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'cantidad',
        header: 'VENTAS'
    },
    {
        field: 'valor_total',
        header: 'TOTAL'
    }
]
export const COLUMNAS_CIERRE_DIARIO_MEDIOS_PAGOS = [{
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'ventas',
        header: 'VENTAS'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_VENTAS_COMBUSTIBLE = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANT.'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'islas',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
]

export const COLUMNAS_VENTAS_COMBUSTIBLE_PROMOTOR = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANT.'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'medio_de_pago',
        header: 'MEDIO DE PAGO'
    },
    {
        field: 'islas',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
    /* ,
      {
        field: 'acciones',
        header: 'ACCIONES'
      } */
]

export const COLUMNAS_ANULACION_FACTURA = [{
        field: 'consecutivo_anulacion',
        header: 'CONT. ANULACION'
    },
    {
        field: 'venta',
        header: 'VENTA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'impuesto_total',
        header: 'IMPUESTO TOTAL'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    },
]
export const COLUMNAS_VENTAS_COMBUSTIBLE_CLIENTES = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    }, {
        field: 'identificacion',
        header: 'IDENTIFICACION'
    }, {
        field: 'cliente',
        header: 'CLIENTE'
    }, {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'tipo',
        header: 'TIPO'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    }, {
        field: 'precio_diferencial',
        header: 'PRECIO DIFERENCIAL'
    },
    {
        field: 'cantidad',
        header: 'CANT.'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]


export const COLUMNAS_VENTAS_RECUADOS_DETALLADAS = [{
        field: 'consecutivo',
        header: 'VENTA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'alias',
        header: 'ESTACION'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'lectura_inicial',
        header: 'LECTURA INICIAL'
    },
    {
        field: 'lectura_final',
        header: 'LECTURA FINAL'
    },
    {
        field: 'nombre_producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'sub_total',
        header: 'MONTO'
    },
    {
        field: 'venta_total',
        header: 'TOTAL'
    },
]

export const COLUMNAS_DETALLES_VENTAS_COMBUSTIBLE = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCIÓN'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'b_gravable',
        header: 'B. GRAVABLE'
    },
    {
        field: 'impuestos',
        header: 'IMPUESTOS'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_VENTAS_CANASTILLA = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'imp_venta',
        header: 'IMP. VENTA'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]
export const COLUMNAS_VENTAS_KIOSCO = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'venta_neta',
        header: 'VENTA NETA'
    },
    {
        field: 'imp_venta',
        header: 'IMP. VENTA'
    },
    {
        field: 'venta_bruta',
        header: 'VENTA BRUTA'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_VENTAS_KIOSCO_DETALLADAS = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'venta',
        header: 'VENTA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'precio_especial',
        header: 'PRECIO ESPECIAL'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    }, {
        field: 'venta_neta',
        header: 'VENTA NETA'
    },
    {
        field: 'tarifa_iva',
        header: 'TARIFA IVA'
    }, {
        field: 'valor_iva',
        header: 'VALOR IVA'
    },
    {
        field: 'venta_bruta',
        header: 'VENTA BRUTA'
    }
]

export const COLUMNAS_VENTAS_ANULADAS = [{
        field: "estacion",
        header: "ESTACIÓN",
    }, {
        field: "tipo",
        header: "TIPO",
    }, {
        field: "consecutivo_anulacion",
        header: "CON. ANULACIÓN",
    }, {
        field: "consecutivo_venta",
        header: "VENTA",
    },
    {
        field: "fecha_anulacion",
        header: "FECHA ANULACIÓN",
    },
    {
        field: "responsable_anulacion",
        header: "RESPONSABLE ANULACION",
    }, {
        field: "venta_total",
        header: "VENTA TOTAL",
    },
    {
        field: "acciones",
        header: "ACCIONES",
    }
]
export const COLUMNAS_VENTAS_ELECTRONICAS_ANULADAS = [{
        field: "alias",
        header: "ESTACIÓN",
    }, {
        field: "tipo",
        header: "TIPO",
    }, {
        field: "consecutivo_anulacion",
        header: "CON. ANULACIÓN",
    }, {
        field: "consecutivo_venta",
        header: "VENTA",
    },
    {
        field: "fecha_anulacion",
        header: "FECHA ",
    }, {
        field: "motivo_anulacion",
        header: "MOTIVO DE ANULACION",
    },
    {
        field: "responsable_anulacion",
        header: "RESPONSABLE",
    }, {
        field: "venta_total",
        header: "VENTA TOTAL",
    },
    {
        field: "acciones",
        header: "ACCIONES",
    }
]

export const COLUMNAS_VENTAS_POR_PLACA = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'imp_venta',
        header: 'IMP. VENTA'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'odometro',
        header: 'ODOMETRO'
    },
    {
        field: 'numero_interno',
        header: 'NUMERO INTERNO'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_VENTAS_POR_CLIENTE = [{
        field: 'estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'cliente',
        header: 'CLIENTE'
    },
    {
        field: 'galon',
        header: 'GALONAJE'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'numero_interno',
        header: 'NUMERO'
    },
    {
        field: 'anticipo',
        header: 'ORDEN'
    },
    {
        field: 'kilom',
        header: 'KILOMETRAJE'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_REPORTE_CALIBRACIONES = [{
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    }
]

export const COLUMNAS_REPORTE_CONSUMO_PROPIO = [{
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'total',
        header: 'TOTAL'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    }
]

export const COLUMNAS_REPORTE_ARQUEO = [{
        field: 'tienda',
        header: 'TIENDA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'hora_inicio',
        header: 'FECHA INICIO'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_REPORTE_ARQUEO_VENTAS = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'subtotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto_total',
        header: 'IMPUESTO'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_REPORTE_ARQUEO_CONSIGNACIONES = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'valor_monedas',
        header: 'MONEDA'
    },
    {
        field: 'valor_billetes',
        header: 'BILLETE'
    },
    {
        field: 'total',
        header: 'TOTAL'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    }
]

export const COLUMNAS_REPORTE_ALERTA_CONVERSIONES = [{
        field: 'consecutivo',
        header: 'Con.'
    },
    {
        field: 'fecha_conversion',
        header: 'F. Conversion'
    },
    {
        field: 'razon_social',
        header: 'Taller'
    },
    {
        field: 'cliente',
        header: 'Cliente'
    },
    {
        field: 'placa',
        header: 'Vehiculo'
    },
    {
        field: 'tipo',
        header: 'Tipo'
    },
    {
        field: 'fecha_verificado',
        header: 'F. Verificado'
    },
    {
        field: 'fecha_proxima_verificacion',
        header: 'F. Vencimiento'
    },
    {
        field: 'estado',
        header: 'ESTADO'
    }
]

export const COLUMNAS_REPORTE_COMPRAS = [{
        field: 'codigo',
        header: 'CODIGO'
    },
    {
        field: 'alias',
        header: 'ALIAS'
    },
    {
        field: 'proveedor',
        header: 'PROVEEDOR'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'costo_total',
        header: 'COSTO TOTAL'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_REPORTE_DETALLES_COMPRA = [{
        field: 'descripcion',
        header: 'DESCRIPCION PRODUCTO'
    },
    {
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'costo_producto',
        header: 'COSTO PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'sub_total',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto',
        header: 'IMPUESTO'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const COLUMNAS_REPORTE_KARDEX_COMBUSTIBLE = [{
        field: 'consecutivo',
        header: 'MOVIMIENTO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    }, {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'operacion',
        header: 'OPERACIÓN'
    },
    {
        field: 'tipo',
        header: 'TIPO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'costo_producto',
        header: 'COSTO PRODUCTO'
    },
    {
        field: 'sub_total',
        header: 'COSTO MOVIMIENTO'
    }
]

export const COLUMNAS_REPORTE_UTILIDAD = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'operario',
        header: 'OPERARIO'
    },
    {
        field: 'costo',
        header: 'COSTO'
    },
    {
        field: 'venta_neta',
        header: 'VENTA NETA'
    },
    {
        field: 'impuestos',
        header: 'IMPUESTOS'
    },
    {
        field: 'ipocon',
        header: 'IPOCON'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    },
    {
        field: 'utilidad',
        header: 'UTILIDAD'
    },
    {
        field: 'util_price',
        header: 'UTILIDAD (%)'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_REPORTE_DETALLES_UTILIDAD = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCIÓN'
    },
    {
        field: 'costo',
        header: 'COSTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'valor_neto',
        header: 'V. NETO'
    },
    {
        field: 'iva',
        header: 'IVA'
    },
    {
        field: 'impoconsumo',
        header: 'IMPOCON'
    },
    {
        field: 'total',
        header: 'TOTAL'
    },
    {
        field: 'util',
        header: 'UTIL(%)'
    },
    {
        field: 'util_price',
        header: 'UTIL($)'
    }
]

export const COLUMNAS_BALANCE_ACUMULADO = [{
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'codigo_producto',
        header: 'CODIGO PRODUCTO'
    },
    {
        field: 'nombre_producto',
        header: 'NOMBRE PRODUCTO'
    },
    {
        field: 'fecha_inicial',
        header: 'FECHA INICIAL'
    },
    {
        field: 'fecha_final',
        header: 'FECHA FINAL'
    },
    {
        field: 'medida_inicial',
        header: 'SALDO INICIAL'
    },
    {
        field: 'ingreso_factura',
        header: 'INGRESO FACTURA'
    },
    {
        field: 'ventas_contado',
        header: `VENTA CONTADO
    (EFECTIVO, T. DEBITO/CREDITO)`
    },
    {
        field: 'venta_credito',
        header: `VENTAS CREDITO
    (RUMBO)`
    },
    {
        field: 'ventas_regalo',
        header: 'VENTAS PROMOCIONALES'
    },
    {
        field: 'total_venta_surtidor',
        header: 'TOTAL VENTAS SURTIDOR'
    },
    {
        field: 'consumos_propios',
        header: 'CONSUMO PROPIO'
    },
    {
        field: 'total_salida',
        header: 'TOTAL SALIDA'
    },
    {
        field: 'existencia_real',
        header: 'EXISTENCIA TOTAL REAL'
    },
    {
        field: 'faltantes_sobrantes',
        header: 'FALTANTES O SOBRANTES'
    }
]

export const COLUMNAS_REPORTE_DIFERENCIA_RECIBO = [{
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'lectura_inicial',
        header: 'LECTURA INICIAL'
    },
    {
        field: 'lectura_final',
        header: 'LECTURA FINAL'
    },
    {
        field: 'galones_salida',
        header: 'GALONES SALIDA'
    },
    {
        field: 'galones_consumo',
        header: 'GALONES CONSUMO'
    },
    {
        field: 'galenoes_calibracion',
        header: 'GALONES CALIBRACION'
    },
    {
        field: 'venta_total_galones',
        header: 'VENTA T. GALONES'
    },
    {
        field: 'vol_galones_ventas',
        header: 'VOL. GALONES VENTA'
    },
    {
        field: 'venta_total_pesos',
        header: 'VENTA TOTAL PESOS'
    }
]

export const COLUMNAS_REPORTE_CONCILIACION_DIARIA = [{
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'nit',
        header: 'NIT'
    },
    {
        field: 'alias',
        header: 'ESTACION'
    },
    {
        field: 'razon_social',
        header: 'RAZON SOCIAL'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_JORNADA = [{
        field: 'identificacion',
        header: 'IDENTIFICACION'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'fecha_inicio_datetime',
        header: 'APERTURA'
    },
    {
        field: 'fecha_fin_datetime',
        header: 'CIERRE'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_COMBUSTIBLE = [{
        field: 'descripcion',
        header: 'PRODUCTO'
    },
    {
        field: 'precio_peso',
        header: 'PRECIO'
    },
    {
        field: 'transacciones',
        header: 'TRANSACCIONES'
    },
    {
        field: 'volumen_formateado',
        header: 'VOLUMEN'
    },
    {
        field: 'subtotal_peso',
        header: 'SUBTOTAL'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_CANASTILLA = [{
        field: 'descripcion',
        header: 'PRODUCTO'
    },
    {
        field: 'precio_peso',
        header: 'PRECIO'
    },
    {
        field: 'transacciones',
        header: 'TRANSACCIONES'
    },
    {
        field: 'volumen_formateado',
        header: 'VOLUMEN'
    },
    {
        field: 'subtotal_peso',
        header: 'TOTAL'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_CALIBRACIONES = [{
        field: 'fecha_datetime',
        header: 'FECHA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'descripcion',
        header: 'PRODUCTO'
    },
    {
        field: 'volumen_formateado',
        header: 'VOLUMEN'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_CONSUMOS_PROPIOS = [{
        field: 'fecha_datetime',
        header: 'FECHA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'descripcion',
        header: 'PRODUCTO'
    },
    {
        field: 'volumen_formateado',
        header: 'VOLUMEN'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
]

export const COLUMNAS_CONCILIACION_DIARIA_DETALLE_ALIVIOS_CAJA = [{
        field: 'fecha_datetime',
        header: 'FECHA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'numero',
        header: 'NUMERO'
    },
    {
        field: 'monedas',
        header: 'TOTAL EN MOENDAS'
    },
    {
        field: 'billetes',
        header: 'TOTAL EN BILLETES'
    },
    {
        field: 'venta_total_peso',
        header: 'TOTAL SOBRE'
    }
]

export const COLUMNAS_REPORTE_MILLAS = [{
        field: 'cliente',
        header: 'CLIENTE'
    },
    {
        field: 'cedula',
        header: 'CEDULA CLIENTE'
    },
    {
        field: 'consecutivo',
        header: 'CONSECUTIVO VENTA'
    },
    {
        field: 'ventas',
        header: 'TOTAL VENTA'
    }
]

export const COLUMNAS_REPORTE_LIBRO_FACTURACION_VENTAS_CATEG = [{
        field: 'categoria',
        header: 'CATEGORIA'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'excluido',
        header: 'EXCLUIDO'
    },
    {
        field: 'base_gravable',
        header: 'BASE GRAVABLE'
    },
    {
        field: 'tarifa',
        header: 'TARIFA'
    },
    {
        field: 'valor_impuesto',
        header: 'VALOR IMPUESTO'
    },
    {
        field: 'valor_total',
        header: 'VALOR TOTAL'
    }
]

export const COLUMNAS_ANULAR_CARGAS = [{
        field: 'consecutivo',
        header: 'VENTA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'alias',
        header: 'ESTACION'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'nombre_producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'sub_total',
        header: 'MONTO'
    },
    {
        field: 'recaudo',
        header: 'RECAUDO'
    },
    {
        field: 'venta_total',
        header: 'TOTAL'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const COLUMNAS_CARGAS_ANULADAS = [{
        field: 'consecutivo',
        header: 'VENTA'
    },
    {
        field: 'fecha',
        header: 'FECHA DE CARGA'
    },
    {
        field: 'alias',
        header: 'ESTACION'
    },
    {
        field: 'vehiculo_placa',
        header: 'PLACA'
    },

    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    }

]

export const REPORTE_VENTAS_DETALLADO = [{
        field: 'codigo',
        header: 'CODIGO'
    },
    {
        field: 'nombre_estacion',
        header: 'ESTACIÓN'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'turno',
        header: 'TURNO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'isla',
        header: 'ISLAS'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'lectura_inicial',
        header: 'LECTURA INICIAL'
    },
    {
        field: 'lectura_final',
        header: 'LECTURA FINAL'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'tipo_factura',
        header: 'TIPO FACTURA'
    },
    {
        field: 'consecutivo_factura',
        header: 'CONSECUTIVO DE FACTURA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    }, {
        field: 'precio_diferencial',
        header: 'PRECIO DIFERENCIAL'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'valor_venta_total',
        header: 'VALOR VENTA'
    },
    {
        field: 'descuento',
        header: 'DESCUENTO'
    },
    {
        field: 'metodo_pago',
        header: 'MEDIO PAGO'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'tipo_documento_fe',
        header: 'TIPO DE DOCUMENTO FE'
    },
    {
        field: 'numero_documento_fe',
        header: 'NUMERO DE DOCUMENTO FE'
    },
    {
        field: 'nombre_cliente',
        header: 'NOMBRE CLIENTE'
    },

    {
        field: 'kilometraje',
        header: 'KILOMETRAJE'
    },
]

export const REPORTE_VENTAS_DETALLADO_ANTD = [{
        dataIndex: 'codigo',
        title: 'CODIGO',
        sorter: true
    },
    {
        dataIndex: 'nombre_estacion',
        title: 'ESTACIÓN',
        sorter: true
    },
    {
        dataIndex: 'promotor',
        title: 'PROMOTOR',
        sorter: true
    },
    {
        dataIndex: 'turno',
        title: 'TURNO',
        sorter: true
    },
    {
        dataIndex: 'fecha',
        title: 'FECHA',
        sorter: true
    },
    {
        dataIndex: 'hora',
        title: 'HORA',
        sorter: true
    },
    {
        dataIndex: 'isla',
        title: 'ISLA',
        sorter: true
    },
    {
        dataIndex: 'surtidor',
        title: 'SURTIDOR',
        sorter: true
    },
    {
        dataIndex: 'cara',
        title: 'CARA',
        sorter: true
    },
    {
        dataIndex: 'manguera',
        title: 'MANGUERA',
        sorter: true
    },
    {
        dataIndex: 'lectura_inicial',
        title: 'LECTURA INICIAL',
        sorter: true
    },
    {
        dataIndex: 'lectura_final',
        title: 'LECTURA FINAL',
        sorter: true
    },
    {
        dataIndex: 'tanque',
        title: 'TANQUE',
        sorter: true
    },
    {
        dataIndex: 'tipo_factura',
        title: 'TIPO FACTURA',
        sorter: true
    },
    {
        dataIndex: 'consecutivo_factura',
        title: 'CONSECUTIVO DE FACTURA',
        sorter: true
    },
    {
        dataIndex: 'referencia',
        title: 'REFERENCIA',
        sorter: true
    },
    {
        dataIndex: 'producto',
        title: 'PRODUCTO',
        sorter: true
    },
    {
        dataIndex: 'precio',
        title: 'PRECIO',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    }, {
        dataIndex: 'precio_diferencial',
        title: 'PRECIO DIFERENCIAL',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'cantidad',
        title: 'CANTIDAD',
        sorter: true
    },
    {
        dataIndex: 'unidad',
        title: 'UNIDAD',
        sorter: true
    },
    {
        dataIndex: 'descuento',
        title: 'DESCUENTO',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'valor_venta_total',
        title: 'VALOR VENTA',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'metodo_pago',
        title: 'MEDIO PAGO',
        sorter: true
    },
    {
        dataIndex: 'medio_autorizacion',
        title: 'MEDIO DE IDENTIFICACIÓN',
        sorter: true
    },
    {
        dataIndex: 'placa',
        title: 'PLACA',
        sorter: true
    },
    {
        dataIndex: 'tipo_documento_fe',
        title: 'TIPO DE DOCUMENTO FE',
        sorter: true
    },
    {
        dataIndex: 'numero_documento_fe',
        title: 'NUMERO DE DOCUMENTO FE',
        sorter: true
    },
    {
        dataIndex: 'nombre_cliente',
        title: 'NOMBRE CLIENTE',
        sorter: true
    },

    {
        dataIndex: 'kilometraje',
        title: 'KILOMETRAJE',
        sorter: true
    },
    {
        dataIndex: 'codigo_sap_cliente',
        title: 'CÓDIGO SAP',
        sorter: true
    },
]

export const REPORTE_RECAUDO_VENTAS = [
    {
        dataIndex: 'consecutivo',
        title: 'CONSECUTIVO',
        sorter: true
    },
    {
        dataIndex: 'fecha',
        title: 'FECHA',
        sorter: true,
        width: 150
    },
    {
        dataIndex: 'eds',
        title: 'ESTACIÓN',
        sorter: true
    },
    {
        dataIndex: 'promotor',
        title: 'PROMOTOR',
        sorter: true
    },
    {
        dataIndex: 'identificacion',
        title: 'IDENTIFICACIÓN',
        sorter: true
    },
    {
        dataIndex: 'nombre_cliente',
        title: 'NOMBRE CLIENTE',
        sorter: true
    },
    {
        dataIndex: 'placa',
        title: 'PLACA',
        sorter: true
    },
    {
        dataIndex: 'producto',
        title: 'PRODUCTO',
        sorter: true
    },
    {
        dataIndex: 'cantidad',
        title: 'CANTIDAD',
        sorter: true,
        render: (value) => `${setearMiles(value)}`
    },
    {
        dataIndex: 'unidad',
        title: 'UNIDAD',
        sorter: true
    },
    {
        dataIndex: 'precio',
        title: 'PRECIO',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'subtotal_venta',
        title: 'SUBTOTAL',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'recaudo',
        title: 'RECAUDO',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'total',
        title: 'TOTAL',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
    {
        dataIndex: 'medio_pago',
        title: 'M. PAGO',
        sorter: true
    }
]

export const REPORTE_VENTAS_CONSOLIDADO_ANTD = [{
        dataIndex: 'codigo_producto',
        title: 'COD. PRODUCTO',
        sorter: true

    },
    {
        dataIndex: 'producto',
        title: 'PRODUCTO',
        sorter: true

    },
    {
        dataIndex: 'precio',
        title: 'PRECIO VENTA',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`


    }, {
        dataIndex: 'cantidad',
        title: 'CANTIDAD VENDIDA',
        sorter: true

    },
    {
        dataIndex: 'valor_venta_total',
        title: 'VALOR VENTA',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`

    },
    {
        dataIndex: 'valor_descuento_total',
        title: 'VALOR DESCUENTO',
        sorter: true,
        render: (value) => `$${setearMiles(value)}`
    },
]
export const REPORTE_VENTAS_CONSOLIDADO2 = [{
        field: 'codigo_producto',
        header: 'COD. PRODUCTO'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio_venta',
        header: 'PRECIO VENTA',
        render: (value) => `$${setearMiles(value)}`
    }, {
        field: 'volumen_vendido',
        header: 'CANTIDAD VENDIDA'
    },
    {
        field: 'valor_venta',
        header: 'VALOR VENTA',
        render: (value) => `$${setearMiles(value)}`
    },
    {
        field: 'valor_descuento',
        header: 'VALOR DESCUENTO',
        render: (value) => `$${setearMiles(value)}`
    },
]
export const REPORTE_VENTAS_CONSOLIDADO = [{
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'nombre_cliente',
        header: 'NOMBRE CLIENTE'
    },
    {
        field: 'tipo_cliente',
        header: 'TIPO CLIENTE'
    },
    {
        field: 'canal',
        header: 'CANAL'
    },
    {
        field: 'cod_cliente_solicitante',
        header: 'COD. CLIENTE SOLICITANTE'
    },
    {
        field: 'cod_cliente_destinatario',
        header: 'COD. CLIENTE DESTINATARIO'
    },
    {
        field: 'centro_costos',
        header: 'CENTRO COSTO'
    },
    {
        field: 'codigo_producto',
        header: 'COD. PRODUCTO'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio_venta',
        header: 'PRECIO VENTA'
    },
    {
        field: 'valor_venta',
        header: 'VALOR VENTA'
    },
    {
        field: 'volumen_vendido',
        header: 'CANTIDAD VENDIDA'
    },
    {
        field: 'valor_descuento',
        header: 'VALOR DESCUENTO'
    },
]

export const REPORTE_VENTAS_DETALLADO_MEDIOS_PAGOS = [{
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'valor_total',
        header: 'VALOR TOTAL'
    },
]
export const REPORTE_VENTAS_DETALLADO_IMPUESTO = [{
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'impuesto_valor',
        header: 'VALOR TOTAL'
    },
]
export const REPORTE_VENTAS = [{
        field: 'con',
        header: 'CON.'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 't_venta',
        header: 'T. VENTA'
    },
    {
        field: 'medio_pago',
        header: 'M. DE PAGO'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const REPORTE_PRODUCTOS_ACUERDOS = [{
        field: 'codigo',
        header: 'CODIGO'
    },
    {
        field: 'acuerdo',
        header: 'ACUERDO'
    },
    {
        field: 'pl_u',
        header: 'PLU'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'costo',
        header: 'COSTO'
    },
    {
        field: 'minimo',
        header: 'MINIMO'
    },
    {
        field: 'maximo',
        header: 'MAXIMO'
    },
]

export const REPORTE_CIERRES = [{
        field: 'descripcion',
        header: 'TURNO'
    },
    {
        field: 'nombre',
        header: 'EMPLEADO'
    },
    {
        field: 'fecha_recibido',
        header: 'FECHA'
    },
    {
        field: 'hora_inicio',
        header: 'HORA INICIO'
    },
    {
        field: 'hora_fin',
        header: 'HORA FIN'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const CIERRE_DETALLES_LECTURA = [{
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto_descripcion',
        header: 'ARTICULO'
    },
    {
        field: 'lectura_inicial',
        header: 'LECTURA INICIAL'
    },
    {
        field: 'lectura_final',
        header: 'LECTURA FINAL'
    },
    {
        field: 'lectura_diferencia',
        header: 'LECTURA DIFERENCIA'
    },
]
export const ARQUEO_DETALLES_LECTURA = [{
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto_descripcion',
        header: 'ARTICULO'
    },
    {
        field: 'lectura_inicial',
        header: 'LECTURA INICIAL'
    }
]

export const CIERRE_DETALLES_LECTURA_TERPEL = [{
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto_descripcion',
        header: 'ARTICULO'
    },
    {
        field: 'lectura_inicial',
        header: 'LI. VOLUMEN'
    },
    {
        field: 'lectura_final',
        header: 'LF. VOLUMEN'
    },
    {
        field: 'lectura_diferencia',
        header: 'DIFF. VOLUMEN'
    }
    /*,
      {
        field: 'venta_inicial',
        header: 'LI. VENTA'
      },
      {
        field: 'venta_final',
        header: 'LF. VENTA'
      },
      {
        field: 'venta_diferencia',
        header: 'DIFF. VENTA'
      }*/
]

export const CIERRE_DETALLES_MEDIO_PAGO = [{
        field: 'descripcion',
        header: 'FORMA DE PAGO'
    }, {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]
export const CIERRE_DETALLES_PROMOTOR = [{
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]
export const CIERRE_DETALLES_PRODUCTO = [{
        field: 'descripcion',
        header: 'PRODUCTO'
    }, {
        field: 'precio',
        header: 'PRECIO'
    }, {
        field: 'cantidad',
        header: 'CANTIDAD'
    }, {
        field: 'unidad',
        header: 'UNIDAD'
    },
    {
        field: 'total',
        header: 'TOTAL'
    }
]

export const CIERRE_DETALLES_VENTAS = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    // {
    //   field: 'impuesto',
    //   header: 'IMPUESTOS'
    // },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'subtotal',
        header: 'TOTAL'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    }
]

export const CIERRE_DETALLES_VENTAS_TELPEL = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    }, {
        field: 'surtidor',
        header: 'SURTIDOR'
    }, {
        field: 'cara',
        header: 'CARA'
    },

    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'subtotal',
        header: 'TOTAL'
    }
]

export const CIRRE_DETALLE_SALTOS_LINEA = [{
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'cara',
        header: 'CARA'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    }
]

export const CIERRE_DETALLES_CANASTILLA = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'unidad_medida',
        header: 'UNIDAD MEDIDA'
    },
    {
        field: 'cantidad_venta',
        header: 'CANTIDAD VENTA'
    },
    {
        field: 'sub_total',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuestos',
        header: 'IMPUESTOS'
    },
    {
        field: 'venta_total',
        header: 'VENTA TOTAL'
    }
]
export const CIERRE_DETALLES_CANASTILLAV2 = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'unidad',
        header: 'UNIDAD MEDIDA'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD VENTA'
    },
    {
        field: 'subTotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto',
        header: 'IMPUESTOS'
    },
    {
        field: 'ventaTotal',
        header: 'VENTA TOTAL'
    }
]
export const CIERRE_DETALLES_KIOSCO = [{
        field: 'plu',
        header: 'PLU'
    },
    {
        field: 'descripcion',
        header: 'DESCRIPCION'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD VENTA'
    },
    {
        field: 'subTotal',
        header: 'SUBTOTAL'
    },
    {
        field: 'impuesto',
        header: 'IMPUESTOS'
    },
    {
        field: 'ventaTotal',
        header: 'VENTA TOTAL'
    }
]

export const CIERRE_DETALLES_ALIVIO = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'valor_monedas',
        header: 'VALOR MONEDAS'
    },
    {
        field: 'valor_billetes',
        header: 'VALOR BILLETES'
    }
]
export const CIERRE_DETALLES_CONSIGNACIONES = [{
        field: 'consecutivo',
        header: 'CONSECUTIVO'
    },
    {
        field: 'valor_monedas',
        header: 'MONEDA'
    },
    {
        field: 'valor_billetes',
        header: 'BILLETE'
    },
    {
        field: 'total',
        header: 'TOTAL'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    }
]

export const CIERRE_DETALLES_CALIBRACIONES = [{
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    }
]

export const CIERRE_DETALLES_CONSUMOS_PROPIOS = [{
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'hora',
        header: 'HORA'
    },
    {
        field: 'tanque',
        header: 'TANQUE'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'isla',
        header: 'ISLA'
    },
    {
        field: 'surtidor',
        header: 'SURTIDOR'
    },
    {
        field: 'manguera',
        header: 'MANGUERA'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    },
    {
        field: 'unidad',
        header: 'UNIDAD'
    }
]

export const REPORTE_CONVERSIONES = [{
        field: 'fecha_conversion',
        header: 'FECHA CONVERSION'
    },
    {
        field: 'fecha_revision',
        header: 'FECHA REVISION'
    },
    {
        field: 'proxima_revicion',
        header: 'PROXIMA REVISION'
    },
    {
        field: 'placa',
        header: 'PLACA'
    },
    {
        field: 'identificador',
        header: 'IDENTIFICADOR'
    },
    {
        field: 'taller',
        header: 'TALLER'
    },
    {
        field: 'cliente',
        header: 'CLIENTE'
    },
    {
        field: 'responsable',
        header: 'RESPONSABLE'
    },
    {
        field: 'dictamen',
        header: 'DICTAMEN'
    },
    {
        field: 'conversion_anual',
        header: 'TIPO'
    },
    {
        field: 'documento',
        header: 'DOCUMENTO'
    }
]

export const REPORTE_CONSOLIDADO_TURNO = [{
        field: 'turno',
        header: 'TURNO'
    },
    {
        field: 'jornada',
        header: 'JORNADA'
    },
    {
        field: 'hora_inicio',
        header: 'HORA INICIO'
    },
    {
        field: 'hora_fin',
        header: 'HORA FIN'
    },
    {
        field: 'acciones',
        header: 'ACCIONES'
    }
]

export const REPORTE_PRODUCT_X_EMPRESA_BODEGA = [{
        field: 'grupo',
        header: 'CATEGORIA'
    },
    {
        field: 'plu',
        header: 'PLU'
    }, {
        field: 'descripcion',
        header: 'DESCRIPCION'
    }, {
        field: 'codigos_barras',
        header: 'CODIGO DE BARRA'
    }, {
        field: 'bodega',
        header: 'BODEGA'
    }, {
        field: 'precio',
        header: 'PRECIO'
    }, {
        field: 'costos',
        header: 'COSTO'
    }, {
        field: 'saldo',
        header: 'SALDO'
    }
]
