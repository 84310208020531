import React from 'react'
import { IDockChildrenItemProps } from '../DockItemMenu'
import { Button } from 'antd'
import { StarOutlined } from '@ant-design/icons'
import { useDockMenu } from 'context/DockMenuProvider'
export interface IProps {
    items: IDockChildrenItemProps[]
    hide:()=>void
}
const ListChildrenItemDock: React.FC<IProps> = ({ items,hide }) => {
  const {setIsVisible}=useDockMenu()

    return (
        <div className='list-dock-menu'>
            {items.map((it) => (<Button className='item-dock-menu-button' onClick={()=>{
                hide()
                setIsVisible(false)
            }}>
                <StarOutlined /> {it.title} 
            </Button>))}
        </div>
    )
}

export default ListChildrenItemDock
