import { Paper } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, DatePicker, Input, Select, notification, TimePicker, Radio } from 'antd';
import { OptionProps } from 'antd/es/select';
import './index.css';
import { filterOptions } from 'util/funcionesNews';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

interface FiltrosProps {
    showTime?: boolean;
    formatInputRangePicker?: 'YYYY-MM-DD' | 'DD-MM-YYYY' | 'YYYY/MM/DD' | 'DD/MM/YYYY';
    checkA?: boolean;
    labelCheckA?: string;
    timePickerA?: boolean;
    timePickerB?: boolean;
    rangePicker?: boolean;
    maxMonthsDifference?: number; // Changed to maxMonthsDifference
    selectA?: boolean;
    selectB?: boolean;
    selectC?: boolean;
    inputA?: boolean;
    placeholderInputA?: string;
    isMultiSelectSelectA?: boolean;
    isMultiSelectSelectB?: boolean;
    isMultiSelectSelectC?: boolean;
    placeholderSelectA?: string;
    placeholderSelectB?: string;
    placeholderSelectC?: string;
    placeholderTimePickerA?: string;
    placeholderTimePickerB?: string;
    optionsSelectA?: Omit<OptionProps, 'children'>[];
    optionsSelectB?: Omit<OptionProps, 'children'>[];
    optionsSelectC?: Omit<OptionProps, 'children'>[];
    onChangeCheckA?: (values: any) => void;
    onChangeRangePicker?: (values: any) => void;
    onChangeSelectA?: (value: any) => void;
    onChangeSelectB?: (value: any) => void;
    onChangeSelectC?: (value: any) => void;
    onChangeInputA?: (values: any) => void;
    onChangeTimePickerA?: (values: any) => void;
    onChangeTimePickerB?: (values: any) => void;
    onClickButton?: () => void;
    textButton?: string;
    loadingButton?: boolean;
    formatDateRange?: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD hh:mm A' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DDTHH:mm';
    formatInputTimePicker?: 'HH:mm:ss' | 'hh:mm A' | 'HH:mm A';
    formatTimePickerOutput?: 'HH:mm:ss' | 'hh:mm A' | 'HH:mm A' | 'HH:mm';
    validateTimePickerAB?: boolean;
    noShadows?: boolean;
    datePicker?: boolean;
    placeholderDatePicker?: string;
    onlyAllowSameMonth?: boolean; // Add this line
    radioGroup?: boolean;
    onChangeRadioGroup?: (value:any) => void;
    optionsRadioGroup?:{label:string, value:number|string}[]
    onChangeDatePicker?: (value: any) => void;
}

interface Date {
    endDate: dayjs.Dayjs | null;
    startDate: dayjs.Dayjs | null;
}

const FiltrosFC = React.forwardRef((props: FiltrosProps, ref) => {
    const {
        formatInputRangePicker = 'YYYY-MM-DD',
        showTime = false,
        checkA = false,
        labelCheckA = '',
        rangePicker,
        timePickerA = false,
        timePickerB = false,
        selectA,
        selectB,
        selectC,
        inputA,
        isMultiSelectSelectA = false,
        isMultiSelectSelectB = false,
        isMultiSelectSelectC = false,
        formatInputTimePicker = 'hh:mm A',
        formatTimePickerOutput,
        onChangeCheckA,
        onChangeRangePicker,
        onChangeSelectA,
        onChangeSelectB,
        onChangeSelectC,
        onChangeTimePickerA,
        onChangeTimePickerB,
        onChangeInputA,
        onChangeDatePicker,
        optionsSelectA = [],
        optionsSelectB = [],
        optionsSelectC = [],
        placeholderSelectA,
        placeholderSelectB,
        placeholderSelectC,
        placeholderInputA,
        placeholderTimePickerA,
        placeholderTimePickerB,
        onClickButton,
        maxMonthsDifference = 3, // Default to 3 months
        validateTimePickerAB = false,
        textButton = 'Buscar',
        loadingButton = false,
        formatDateRange = 'YYYY-MM-DD',
        noShadows,
        datePicker = false,
        placeholderDatePicker,
        onlyAllowSameMonth = false, // Add this line
        radioGroup=false,
        onChangeRadioGroup,
        optionsRadioGroup = []

    } = props;

    const [rangeDates, setRangeDates] = useState<Date>({
        startDate: null,
        endDate: null,
    });

    const [checksValue, setChecksValue] = useState<any>({ A: false });
    const [timePickerValues, setTimePickerValues] = useState<any>({ A: null, B: null, C: null });
    const [rangeDatesValue, setRangeDatesValue] = useState<any>([]);
    const [setselectValues, setSetselectValues] = useState({
        A: isMultiSelectSelectA ? [] : null,
        B: isMultiSelectSelectB ? [] : null,
        C: isMultiSelectSelectC ? [] : null,
    });
    const [blockTime, setBlockTime] = useState(false);

    const handlerChecks = (name: 'A', value: any) => {
        setChecksValue((prev: any) => ({ ...prev, [name]: value }));
    };

    const handlerSelectsValues = (name: 'A' | 'B' | 'C', value: any) => {
        setSetselectValues((prev) => ({ ...prev, [name]: value }));
    };

    const handlerTimePickerValue = (name: 'A' | 'B', value: any) => {
        setTimePickerValues((prev: any) => ({ ...prev, [name]: value }));
    };

    const disabledDates = (current: any) => {
        const startDate = dayjs('2024-01-01');
        const today = dayjs();
        return current && (current.isAfter(today) || current.isBefore(startDate));
    };

    const monthsDiff = useMemo(() => {
        if (!rangeDates.startDate || !rangeDates.endDate) return 0;
        return rangeDates.endDate.diff(rangeDates.startDate, 'months');
    }, [rangeDates]);

    const resetSelectA = () => handlerSelectsValues('A', null);
    const resetSelectB = () => handlerSelectsValues('B', null);
    const resetSelectC = () => handlerSelectsValues('C', null);
    const resetCheckA = () => handlerChecks('A', false);
    const resetTimePickerA = () => handlerTimePickerValue('A', null);
    const resetTimePickerB = () => handlerTimePickerValue('B', null);

    React.useImperativeHandle(ref, () => ({
        resetSelectB,
        resetSelectA,
        resetSelectC,
        resetCheckA,
        resetTimePickerA,
        resetTimePickerB,
    }));

    useEffect(() => {
        if (validateTimePickerAB && timePickerValues.A && timePickerValues.B) {
            if (timePickerValues.B.isBefore(timePickerValues.A) && monthsDiff === 0) {
                notification.warning({
                    message: 'Atención',
                    description: 'Hora fin no puede ser menor o igual que hora inicial',
                });
                setBlockTime(true);
            } else {
                setBlockTime(false);
            }
        }
    }, [timePickerValues, monthsDiff]);

    useEffect(() => {
        if (rangePicker && monthsDiff >= maxMonthsDifference) {
            notification.warning({
                message: 'Rango de fechas invalido',
                description: `Por favor, seleccione un rango de fechas que esté dentro del rango permitido de ${maxMonthsDifference} meses.`,
                placement: 'top',
            });
        }
    }, [monthsDiff]);

    useEffect(() => {
        handlerTimePickerValue('A', null);
        handlerTimePickerValue('B', null);
    }, [timePickerB, timePickerA]);

    useEffect(() => {
        if (!checkA) {
            handlerChecks('A', false);
        }
    }, [checkA]);

    const handleRangePickerChange = (e: any) => {
        if (!e) return;

        const startDate = e[0];
        const endDate = e[1];

        if (onlyAllowSameMonth && startDate && endDate && startDate.month() !== endDate.month()) {
            notification.warning({
                message: 'Rango de fechas inválido',
                description: 'Por favor, seleccione un rango de fechas dentro del mismo mes.',
                placement: 'top',
            });
            return;
        }

        if (onChangeRangePicker) {
            if (formatDateRange) {
                if (showTime) {
                    onChangeRangePicker([startDate?.format(formatDateRange), endDate?.format(formatDateRange)]);
                } else {
                    onChangeRangePicker([startDate?.startOf('day')?.format(formatDateRange), endDate?.endOf('day')?.format(formatDateRange)]);
                }
            } else {
                onChangeRangePicker(e);
            }
        }

        setRangeDates({
            endDate: endDate,
            startDate: startDate,
        });
        setRangeDatesValue(e);
    };

    return (
        <Paper className={`paper-filter ${noShadows ? 'noshadow' : ''}`}>
            <div className={`grid-filters`}>
                {datePicker && (
                    <DatePicker
                        placeholder={placeholderDatePicker}
                        onChange={(e) => {
                            if (onChangeDatePicker) {
                                onChangeDatePicker(e?.format(formatDateRange));
                            }
                        }}
                    />
                )}
                {rangePicker && (
                    <RangePicker
                        status={monthsDiff >= maxMonthsDifference ? 'warning' : undefined}
                        className='select_filter'
                        disabledDate={disabledDates}
                        inputReadOnly
                        value={rangeDatesValue}
                        showTime={
                            showTime
                                ? {
                                      showHour: true,
                                      showMinute: true,
                                      format: 'hh:mm A',
                                      use12Hours: true,
                                  }
                                : false
                        }
                        allowClear={false}
                        onChange={handleRangePickerChange}
                        format={showTime ? 'YYYY/MM/DD hh:mm A' : formatInputRangePicker}
                    />
                )}
                {selectA && (
                    <Select
                        className='select_filter'
                        mode={isMultiSelectSelectA ? 'multiple' : undefined}
                        options={optionsSelectA}
                        placeholder={placeholderSelectA}
                        value={setselectValues.A}
                        onChange={(e) => {
                            if (onChangeSelectA) {
                                onChangeSelectA(e);
                            }
                            handlerSelectsValues('A', e);
                        }}
                        filterOption={filterOptions}
                        showSearch
                        allowClear
                        onClear={() => {
                            if (onChangeSelectA) {
                                onChangeSelectA(null);
                            }
                            handlerSelectsValues('A', null);
                        }}
                    />
                )}
                {checkA && (
                    <div className='checkWrapper'>
                        <Checkbox
                            checked={checksValue.A}
                            onChange={(e) => {
                                if (onChangeCheckA) {
                                    onChangeCheckA(e.target.checked);
                                }
                                handlerChecks('A', e.target.checked);
                            }}
                        >
                            {labelCheckA}
                        </Checkbox>
                    </div>
                )}
                {timePickerA && (
                    <TimePicker
                        allowClear={false}
                        onChange={(e) => {
                            if (!e) return;
                            if (onChangeTimePickerA) {
                                if (formatTimePickerOutput) {
                                    onChangeTimePickerA(e.format(formatTimePickerOutput));
                                } else {
                                    onChangeTimePickerA(e);
                                }
                            }
                            handlerTimePickerValue('A', e);
                        }}
                        value={timePickerValues.A}
                        format={formatInputTimePicker}
                        placeholder={placeholderTimePickerA}
                    />
                )}
                {timePickerB && (
                    <TimePicker
                        allowClear={false}
                        format={formatInputTimePicker}
                        onChange={(e) => {
                            if (!e) return;
                            if (onChangeTimePickerB) {
                                if (formatTimePickerOutput) {
                                    onChangeTimePickerB(e.format(formatTimePickerOutput));
                                } else {
                                    onChangeTimePickerB(e);
                                }
                            }
                            handlerTimePickerValue('B', e);
                        }}
                        value={timePickerValues.B}
                        placeholder={placeholderTimePickerB}
                    />
                )}
                {selectB && (
                    <Select
                        className='select_filter'
                        mode={isMultiSelectSelectB ? 'multiple' : undefined}
                        value={setselectValues.B}
                        options={optionsSelectB}
                        placeholder={placeholderSelectB}
                        onChange={(e) => {
                            if (onChangeSelectB) {
                                onChangeSelectB(e);
                            }
                            handlerSelectsValues('B', e);
                        }}
                        filterOption={filterOptions}
                        showSearch
                        allowClear
                        onClear={() => {
                            if (onChangeSelectB) {
                                onChangeSelectB(null);
                            }
                            handlerSelectsValues('B', null);
                        }}
                    />
                )}
                {selectC && (
                    <Select
                        className='select_filter'
                        mode={isMultiSelectSelectC ? 'multiple' : undefined}
                        value={setselectValues.C}
                        options={optionsSelectC}
                        placeholder={placeholderSelectC}
                        onChange={(e) => {
                            if (onChangeSelectC) {
                                onChangeSelectC(e);
                            }
                            handlerSelectsValues('C', e);
                        }}
                        filterOption={filterOptions}
                        showSearch
                        allowClear
                        onClear={() => {
                            if (onChangeSelectC) {
                                onChangeSelectC(null);
                            }
                            handlerSelectsValues('C', null);
                        }}
                    />
                )}
                {inputA && (
                    <Input
                        className='fieldForm'
                        onChange={(e) => {
                            if (onChangeInputA) {
                                onChangeInputA(e.target.value);
                            }
                        }}
                        placeholder={placeholderInputA}
                    />
                )}
                {
                   radioGroup && <Radio.Group name="radiogroup" onChange={(e)=>{
                    if(onChangeRadioGroup){
                        onChangeRadioGroup(e.target.value)
                    }
                   }}>
                    {
                        optionsRadioGroup.map((item)=><Radio value={item.value}>{item.label}</Radio>)
                    }
                  </Radio.Group>
                }
                <Button className='btn-search' disabled={monthsDiff >= maxMonthsDifference || blockTime} onClick={onClickButton} loading={loadingButton}>
                    {textButton}
                </Button>
            </div>
        </Paper>
    );
});

export default FiltrosFC;
